import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function ComingSoon() {
  return (
    <Layout>
      <Seo title="Coming Soon" />
      <section className="hero bg_img" data-background="/bg/hero.jpg">
        <div className="hero__shape">
          <img src="/elements/hero/shape-2.png" alt="no surrender" />
        </div>
        <div className="el-1">
          <img src="/elements/hero/el-1.png" alt="no surrender" />
        </div>
        <div className="el-2">
          <img src="/elements/hero/el-2.png" alt="no surrender" />
        </div>
        <div className="el-3">
          <img src="/elements/hero/el-3.png" alt="no surrender" />
        </div>
        <div className="el-4">
          <img src="/elements/hero/el-4.png" alt="no surrender" />
        </div>
        <div className="el-5">
          <img src="/elements/hero/el-5.png" alt="no surrender" />
        </div>
        <div className="el-6">
          <img src="/elements/hero/el-6.png" alt="no surrender" />
        </div>
        <div className="el-7">
          <img src="/elements/hero/el-7.png" alt="no surrender" />
        </div>
        <div className="el-8">
          <img src="/elements/hero/el-8.png" alt="no surrender" />
        </div>
        <div className="el-9">
          <img src="/elements/hero/el-9.png" alt="no surrender" />
        </div>
        <div className="el-10">
          <img src="/elements/hero/el-10.png" alt="no surrender" />
        </div>
        <div className="el-11">
          <img src="/elements/hero/el-11.png" alt="no surrender" />
        </div>
      </section>
      <section className="pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero__content d-flex flex-column align-items-center">
                <h2
                  className="hero__title wow fadeInUp text-center"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.5s"
                >
                  Coming Soon
                </h2>
                <div className="about-thumb">
                  <img
                    className="image-1"
                    src="/elements/about-player.png"
                    alt="no surrender"
                  />
                  <img
                    className="image-2"
                    src="/elements/about-phone.png"
                    alt="no surrender"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ComingSoon
